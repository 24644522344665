export const FETCH_PRESET_ORDERS_SUCCESS = 'FETCH_PRESET_ORDERS_SUCCESS'
export const GET_RELATED_PRESET_ORDERS_SUCCESS =
  'GET_RELATED_PRESET_ORDERS_SUCCESS'
export const GET_PRESET_ORDER_SUCCESS = 'GET_PRESET_ORDER_SUCCESS'
export const ADD_PRESET_ORDER_SUCCESS = 'ADD_PRESET_ORDER_SUCCESS'
export const UPDATE_PRESET_ORDER_SUCCESS = 'UPDATE_PRESET_ORDER_SUCCESS'
export const DELETE_PRESET_ORDER_SUCCESS = 'DELETE_PRESET_ORDER_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
