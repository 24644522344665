import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'preset_orders',
  attributes: [
    'id',
    'activate_url',
    'items',
    'started_at',
    'ended_at',
    'is_enabled',
    'is_available',
    'is_expired',
    'is_use_up',
    'usage_limit',
    'orders_count',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['started_at', 'ended_at', 'usage_limit', 'items']
}

export default class PresetOrder extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)

    this.items = (this.items || []).map((item) => {
      const targetVariant = item.target_variant

      return {
        quantity: item.quantity,
        variant: item.variant,
        target_variant: {
          type: targetVariant.type,
          id: targetVariant.id,
          product_id: targetVariant.product_id,
          promoter_share_id: targetVariant.promoter_share_id,
          promoter_share_product_ship_id:
            targetVariant.promoter_share_product_ship_id,
          sales_event_id: targetVariant.sales_event_id,
          sales_event_ship_id: targetVariant.sales_event_ship_id
        }
      }
    })
  }

  toggleEnabled() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_enabled`)
  }

  static activate(token) {
    return axios.put(`${new this().apiBasePath()}/activate`, {
      token: token
    })
  }
}
